<template>
	<div class="gallery">
		<div class="item wow flipInY" data-wow-duration="400ms">
			<img src="@/assets/images/foto-1.jpg" />
		</div>
		<div class="item wow flipInY" data-wow-duration="700ms">
			<img src="@/assets/images/foto-2.jpg" />
		</div>
		<div class="item wow flipInY" data-wow-duration="1000ms">
			<img src="@/assets/images/foto-3.jpg" />
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";

.gallery {
	display: flex;
	.item {
		img {
			max-width: 100%;
			display: block;
		}
	}
}
</style>
