<template>
	<div class="footer">
		<div class="container">
			<div class="logo-bx">
				<img src="@/assets/images/logo.png" />
			</div>

			<div class="info-bx">
				<div class="social-bx">
					<a href="https://www.instagram.com/shoppingtintas/" target="_blank">
						<img src="@/assets/images/instagram.png" />
						<span>shoppingtintas</span>
					</a>
					<a
						href="https://api.whatsapp.com/send?1=pt_br&phone=5551992614397"
						target="_blank"
					>
						<img src="@/assets/images/whatsapp.png" />
						<span>99261.4397</span>
					</a>
					<a
						href="https://api.whatsapp.com/send?1=pt_br&phone=555135291060"
						target="_blank"
					>
						<img src="@/assets/images/whatsapp-white.png" />
						<span>3529.1060</span>
					</a>
				</div>
				<div class="address-bx">
					R. Presidente Kennedy, 543 - Centro, Sapiranga - RS
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";

.footer {
	background: var(--primary);
	padding: 40px 0 60px;

	> .container {
		justify-content: center;
		display: flex;
	}

	.info-bx {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 30px;
	}
	.logo-bx {
		text-align: center;
		img {
			width: 100%;
			max-width: 330px;
		}
	}
	.social-bx {
		display: flex;
		> a {
			text-decoration: none;
			display: flex;
			align-items: center;
			font-weight: 700;
			color: white;
			padding: 7px;

			:hover {
				text-decoration: underline;
			}

			img {
				margin-right: 10px;
			}
		}
	}

	.address-bx {
		color: var(--secondary);
		margin-top: 15px;
		text-align: center;
	}

	@include mobile {
		.container {
			display: block;
		}

		.info-bx {
			margin: 0;
		}

		.social-bx {
			flex-direction: column;
			align-items: center;
			padding: 30px;
		}
	}
}
</style>
