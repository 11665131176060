<template>
	<div class="banner">
		<div class="logo-bx">
			<div class="logo">
				<img src="@/assets/images/logo.png" alt="Shopping das Tintas" />
			</div>
			<div class="text">
				Tintas Prediais, Automotivas
				<br />e Produtos para Piscina.
			</div>
		</div>
		<img src="@/assets/images/banner.jpg" />
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";

.banner {
	position: relative;

	> img {
		width: 100%;
		display: block;
	}

	> .logo-bx {
		text-align: right;
		position: absolute;
		color: white;
		top: 50%;
		left: 50%;
		transform: translate(-100%, -50%);

		> .logo {
			text-align: right;
		}

		img {
			max-width: 600px;
			margin-left: auto;
		}

		> .text {
			display: block;
			text-transform: uppercase;
			width: 100%;
			margin-top: 30px;
			text-align: right;
		}
	}

	@include mobile {
		> .logo-bx {
			img {
				width: 100px;
			}
			> .text {
				margin-top: 10px;
				font-size: 10px;
			}
		}
	}
}
</style>
