<template>
	<div class="banner-slider">
		<div class="container">
			<div class="splide">
				<div class="splide__track">
					<ul class="splide__list">
						<li class="splide__slide">
							<div class="info-bx">
								<h3 class="title">
									Excelência
									<br />em produtos
								</h3>
								<p class="text">
									As melhores marcas para a pintura e acabamento do seu projeto,
									seja ele interno ou externo.
								</p>
							</div>
							<div class="image-bx">
								<img src="@/assets/images/conteudo-1.png" />
							</div>
						</li>
						<li class="splide__slide">
							<div class="info-bx">
								<h3 class="title">
									Simule a cor da
									<br />sua casa com a gente!
								</h3>
								<p class="text">
									Antes de fechar a cor da sua casa, use o nosso simulador para
									ver como ela vai ficar e faça a escolha certa!
								</p>
							</div>
							<div class="image-bx">
								<img src="@/assets/images/conteudo-2.png" />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- <div class="splide">
			<img src="@/assets/images/conteudo-1.png" />
		</div> -->
	</div>
</template>

<script>
import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { onMounted } from "vue";
export default {
	setup() {
		onMounted(() => {
			new Splide(".splide", {
				autoplay: true,
				interval: 5000,
				type: "loop",
			}).mount();
		});
	},
};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";

.banner-slider {
	background-color: rgb(0, 69, 129);
	background-color: linear-gradient(
		180deg,
		rgba(0, 69, 129, 1) 0%,
		rgba(0, 53, 106, 1) 100%
	);
	// background-image: url("../assets/images/house.png");
	background-repeat: no-repeat;
	background-position: bottom left;

	// padding: 60px 0;
	color: white;

	.splide__slide {
		display: flex;
		align-items: center;

		> div {
			flex-grow: 1;
		}
	}
	.splide__arrows {
		display: none;
	}
	.splide__pagination {
		justify-content: flex-start;
		bottom: 30px;
	}
	.info-bx {
		flex-grow: 1;
		padding-right: 30px;
		align-items: center;
	}
	.image-bx {
		img {
			max-width: 100%;
		}
	}
	.title {
		font-size: 24px;
		font-weight: 700;
		margin-top: 0;
	}

	.splide__pagination__page.is-active {
		background-color: var(--secondary);
		opacity: 1;
	}

	@include mobile {
		background-image: none;
		padding: 40px 0;

		.splide__pagination {
			button {
				box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.5);
			}
		}

		.splide__slide {
			flex-direction: column;
		}

		.title {
			font-size: 15px;
		}

		.image-bx {
			margin-top: 10px;
		}

		.text {
			font-size: 12px;
		}
	}
}
</style>
