<template>
	<div class="banner-feature">
		<a
			target="_blank"
			href="https://shoppingtintas.com.br/killing/"
			class="item wow slideInDown"
			data-wow-duration="200ms"
		>
			<img src="@/assets/images/banner-1.png" />
		</a>
		<a
			target="_blank"
			href="https://shoppingtintas.com.br/coral/"
			class="item wow slideInDown"
			data-wow-duration="500ms"
		>
			<img src="@/assets/images/banner-2.png" />
		</a>
		<a
			target="_blank"
			href="https://shoppingtintas.com.br/suvinil/"
			class="item wow slideInDown"
			data-wow-duration="700ms"
		>
			<img src="@/assets/images/banner-3.png" />
		</a>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";

.banner-feature {
	display: flex;
	position: relative;
	overflow: hidden;

	> .item {
		img {
			max-width: 100%;
			display: block;
		}
	}
}
</style>
