<template>
	<div class="adverseting">
		<div class="container">
			<div class="item -text wow slideInLeft" data-wow-duration="700ms">
				As melhores marcas em
				<b>produtos para piscina</b>
			</div>
			<div class="divider"></div>
			<div class="item wow slideInRight" data-wow-duration="700ms">
				<img src="@/assets/images/adversetings.png" />
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";

.adverseting {
	padding: 70px 0;

	> .container {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.divider {
		min-width: 2px;
		background: black;
		min-height: 200px;
		margin: 0 30px;
	}

	.item {
		img {
			max-width: 100%;
		}

		&.-text {
			font-size: 40px;
			text-align: right;
			> b {
				color: var(--primary);
				display: block;
			}
		}
	}

	@include mobile {
		> .container {
			flex-direction: column;
		}

		.item.-text {
			font-size: 22px;
			text-align: center;
			margin-bottom: 40px;
		}

		.divider {
			display: none;
		}
	}
}
</style>
