<template>
	<div class="cta">
		<div class="container">
			<h3 class="title">PARA CASA OU PISCINA</h3>

			<a
				target="_blank"
				href="https://api.whatsapp.com/send?1=pt_br&phone=5551992614397"
				class="btn-whats wow flash"
				data-wow-duration="700ms"
			>
				<img src="@/assets/images/btn-orcamento.png" />
			</a>

			<div class="cc-bx">
				<div class="item -text">
					Entre em contato conosco
					<br />e conheça as melhores ofertas.
				</div>
				<div class="item">
					<img src="@/assets/images/Mastercard-logo.png" />
					<div class="info">*Com parcela mínima de R$50,00.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";
.cta {
	text-align: center;

	.btn-whats {
		img {
			max-width: 100%;
		}
	}

	.title {
		font-size: 90px;
		font-weight: 800;
		color: var(--primary);
		margin-top: 0;
	}

	.cc-bx {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 70px 0;

		.item {
			img {
				width: 100%;
				max-width: 400px;
			}

			&.-text {
				font-size: 18px;
				padding: 40px;
			}

			> .info {
				font-size: 14px;
				margin-top: 20px;
			}
		}
	}

	@include mobile {
		.cc-bx {
			flex-direction: column;
			.item.-text {
				padding: 40px 0;
			}
		}

		.title {
			font-size: 40px;
		}
	}
}
</style>
