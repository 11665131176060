<template>
	<div class="app">
		<banner />
		<banner-feature />
		<banner-slider />
		<gallery />
		<adverseting />
		<cta />
		<Footer />
	</div>
</template>

<script>
import "animate.css/source/animate.css";
import { nextTick, onMounted } from "@vue/runtime-core";
import WOW from "wow.js";
import Adverseting from "./components/Adverseting.vue";
import Banner from "./components/Banner.vue";
import BannerFeature from "./components/BannerFeature.vue";
import BannerSlider from "./components/BannerSlider.vue";
import Cta from "./components/Cta.vue";
import Footer from "./components/Footer.vue";
import Gallery from "./components/Gallery.vue";
export default {
	name: "App",
	components: {
		Banner,
		BannerFeature,
		Gallery,
		Footer,
		Cta,
		Adverseting,
		BannerSlider,
	},

	setup() {
		onMounted(() => {
			nextTick(() => {
				new WOW({
					boxClass: "wow", // default
					animateClass: "animated", // default
					offset: 0, // default
					mobile: true, // default
					live: true,
				}).init();
			});
		});
	},
};
</script>

<style lang="scss">
@import "@/scss/theme.scss";

.app {
	max-width: 1920px;
}
</style>
